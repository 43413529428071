.nav {
  display: flex;
  justify-content: center;
}

.nav li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav li a {
  text-decoration: none;
  color: rgb(16, 81, 109);
  font-family: magistral, sans-serif;
  font-weight: 600;
  font-size: 20px;
  margin: 0 15px;
}

.nav li a:hover,
.socials:hover,
.socials_contact:hover {
  color: rgb(161, 213, 213);
}

.email-link:hover,
.email-footer:hover {
  color: rgb(161, 213, 213);
  text-decoration: none;
}

.hamburger {
  background: none;
  border: none;
}

.hamburger:focus {
  outline: none;
}

.socials,
.email-footer {
  color: white;
}

.socials_contact,
.email-link {
  color: rgb(16, 81, 109);
  font-weight: 600;
}

.header,
.footer {
  padding: 25px 0;
  display: flex;
  align-items: center;
}

.footer {
  background-color: rgb(16, 81, 109);
  color: white;
}

.footer {
  position: relative;
}

.back-to-top {
  position: absolute;
  background-color: rgb(16, 81, 109);
  width: 65px;
  height: 65px;
  transform: rotate(45deg);
  right: 50px;
  top: -30px;
  border-radius: 15px 0 0 0;
}

.to-top {
  position: absolute;
  transform: rotate(-45deg);
}

.main,
.projecten_mb {
  margin-bottom: 27px;
}

.section_title {
  font-family: magistral, sans-serif;
  font-weight: 600;
  margin-bottom: 25px;
}

.margin_section {
  margin-top: 75px;
}

.title_h5 {
  font-family: magistral, sans-serif;
  font-weight: 300;
}

.centered {
  text-align: center;
}

.justify {
  text-align: justify;
}

.link_projects {
  text-decoration: none;
  color: white;
  font-family: magistral, sans-serif;
}

.link_projects:hover {
  text-decoration: none;
  color: rgb(161, 213, 213);
  font-weight: 600;
}

.overlay {
  color: white;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transition: 0.75s ease;
  padding: 15px 0;
}

.light_bg {
  background-color: rgba(161, 213, 213, 0.25);
}

.dark_bg {
  background-color: rgb(16, 81, 109);
}

.purple_bg {
  background-color: rgb(174, 173, 215, 0.15);
}

.circle {
  width: 275px;
  height: 275px;
  border-radius: 50%;
}

.links_sites,
.link_submit_form {
  background-color: rgb(16, 81, 109);
  border-radius: 5px;
  color: white;
  text-decoration: none;
  padding: 15px;
}

.link_submit_form {
  padding: 5px;
}

.links_sites:hover,
.link_submit_form:hover {
  text-decoration: none;
  color: rgb(161, 213, 213);
  font-weight: 600;
}

.h1_projects {
  font-family: magistral, sans-serif;
  font-weight: 300;
  color: rgb(16, 81, 109);
}

.projects_banner {
  height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(
    rgb(161, 213, 213, 0.5),
    rgb(161, 213, 213, 0.25)
  );
}

.banner_title {
  font-family: magistral, sans-serif;
  font-weight: 300;
  color: rgb(16, 81, 109);
  overflow: hidden;
  white-space: nowrap;
  animation: typewriter 1.5s steps(8, end);
}

.animation_banner {
  height: 125px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(
    rgb(161, 213, 213, 0.5),
    rgb(161, 213, 213, 0.25)
  );
}

.animation_banner_img {
  position: absolute;
  height: 100%;
  right: 2.5%;
  z-index: -1;
}

input[type="radio"] {
  display: none;
}

.funFacts_choices {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 50px;
  background-color: rgb(161, 213, 213);
  border-radius: 10px;
  margin: 0 10px 0 10px;
}

input[type="radio"]:checked + .funFacts_choices {
  background-color: rgb(16, 81, 109);
  color: white;
}

.bar-bg {
  height: 25px;
  width: 200px;
  background-color: rgb(16, 81, 109, 0.75);
  flex: 50%;
  display: flex;
  justify-content: end;
}

.bar {
  background-color: rgb(174, 173, 215, 1);
  height: 100%;
  text-align: center;
}

.illustrator,
.html-css,
.react {
  width: 80%;
}

.indesign,
.javascript {
  width: 70%;
}

.photoshop {
  width: 60%;
}

.skill {
  font-weight: 600;
  margin-bottom: 0px;
  flex: 20%;
  display: flex;
  justify-content: start;
}

.skills {
  display: flex;
  width: 100%;
}

#smiley_parts {
  width: 125px;
  height: 150px;
  margin-bottom: 15px;
  position: relative;
}

#smiley {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 25%;
}

#right-arm {
  width: 45%;
  position: absolute;
  top: 37%;
  right: -20%;
  z-index: -1;
}

#left-arm {
  width: 45%;
  position: absolute;
  top: 45%;
  right: 91.5%;
  z-index: -1;
}

#question-mark,
#question-mark2 {
  width: 15%;
  position: absolute;
}

#question-mark {
  top: -3%;
}

#question-mark2 {
  left: 60%;
  transform: rotate(30deg);
}

#map {
  width: 400px;
  height: 400px;
  border-radius: 5px;
}

.labels {
  width: 200px;
}

.input {
  width: 300px;
  height: 30px;
  margin-bottom: 15px;
}

.input_bericht {
  width: 300px;
  height: 200px;
}

textarea {
  vertical-align: top;
}

.contactformulier {
  margin-bottom: 27px;
}

@keyframes typewriter {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .menu-links {
    flex-flow: column wrap;
    width: 100%;
  }

  .menu-links li {
    margin: 10px auto;
  }
}

@media (min-width: 767.98px) {
  .animation_banner {
    height: 150px;
  }
}

@media (min-width: 991.98px) {
  .werk {
    position: relative;
  }

  .overlay {
    position: absolute;
    opacity: 0;
    padding: 0;
  }

  .werk:hover .overlay {
    opacity: 1;
  }

  .animation_banner {
    height: 175px;
  }

  .bar-bg {
    flex: 75%;
  }
}
